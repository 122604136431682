.testimonial {
  display: flex;
  gap: 1;
  padding: 0 2rem;
}
.left-test {
  display: flex;
  flex: 1 1;
  gap: 2rem;
  flex-direction: column;
  text-decoration: uppercase;
  color: white;
}
.left-test > :nth-child(1) {
  color: var(--orange);
  font-weight: bold;
}
.left-test > :nth-child(2),
.left-test > :nth-child(3) {
  font-weight: bold;
  font-size: 3rem;
}
.left-test > :nth-child(4) {
  text-decoration: none;
  text-align: justify;
  letter-spacing: 2px;
  line-height: 40px;
}
.right-test {
  flex: 1;
  position: relative;
}
.right-test > img {
  position: absolute;
  width: 17rem;
  height: 20rem;
  object-fit: cover;
  right: 8rem;
  top: 2rem;
}
.right-test > :nth-child(1) {
  position: absolute;
  width: 17rem;
  height: 20rem;
  border: 2px solid orange;
  background-color: transparent;
  right: 9rem;
  top: 0.9rem;
}
.right-test > :nth-child(2) {
  position: absolute;
  width: 17rem;
  height: 19rem;
  right: 7rem;
  top: 4rem;
  background: var(--planCard);
}
.arrows {
  display: flex;
  gap: 1rem;
  position: absolute;
  left: 30rem;
  /* bottom: 1rem; */
}
.arrows > img {
  width: 1.5rem;
  cursor: pointer;
}
@media screen and (max-width: 768px) {
  .testimonial {
    flex-direction: column;
  }
  .left-test > :nth-child(2),
  .left-test > :nth-child(3) {
    font-size: xx-large;
  }
  .right-test{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2rem;
  }
  .right-test>div{
    position: relative;
    display: none;
  }
  .right-test>img{
    top: 0;
    right: 0;
    position: relative;
    align-self: center;
    margin-top: 1rem;
  }
  .right-test>:last-child{
    display: block;
    bottom: 0;
    left: 0;
  }

}
